<template>
    <div>
      <vue-element-loading :active="appLoading" spinner="bar-fade-scale" color="black" size="128" is-full-screen />
      <v-snackbar v-model="showsnackbar" color="black" right>
        <v-layout wrap justify-center>
          <v-flex text-left class="align-self-center"><span style="color: #fff">
              {{ msg }}
            </span></v-flex>
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showsnackbar = false">
              <v-icon style="color: white">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar>
  
      <v-layout wrap justify-center>
        <v-flex lg11>
  
          <v-layout wrap justify-center pt-10 px-5>
          <v-flex lg6>
            <span style="font-family: Montserrat-Bold; font-size: 28px;">Countries</span>
          </v-flex>
      <v-flex lg6 class="text-end" align-self-end>
        <v-btn color="#00A08E" @click="addCountryDialog = true"><span style="font-family: Montserrat-Regular; font-weight: 600; color: white">Add Country</span></v-btn>
      </v-flex>
    </v-layout>
  
      <v-layout wrap justify-start>
        <v-flex md3 lg3 pt-4 v-for="(item, index) in country" :key="index" class="my-2" pl-3 pr-3>
          <v-card class="tile">
              <v-layout wrap justify-center pa-5>
                  <v-flex md12 lg11 align-self-center>
                      <span style="font-family: Montserrat-Regular; font-size: 16px; font-weight: 600; color: black;">{{ item.name }}</span>
                  </v-flex>

                  <v-flex lg1>
                  <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                    <v-icon v-on="on">mdi-dots-vertical</v-icon>
                  </template>
                  <v-list height="48px" class="pa-0">
                    <v-list-item  @click="countryEdit(item._id,item.name,item.currencyName,item.currencyCode,item.currencySymbol)">
                      <v-list-item-title>
                        <span style="font-family: Lato-Regular; font-weight: 400">
                          <v-icon color="black">mdi-pencil</v-icon>&nbsp; Edit
                        </span>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                  <v-list height="48px" class="pa-0">
                    <v-list-item @click="deleteRegion(item._id)">
                      <v-list-item-title>
                        <span style="font-family: Lato-Regular; font-weight: 400">
                          <v-icon color="black">mdi-delete</v-icon>&nbsp; Delete
                        </span>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                </v-flex>

                <v-flex lg10>
                  <v-layout wrap justify-center>

                  
                  <v-flex md12 lg12 align-self-center>
                      <span style="font-family: Lato-Regular; font-size: 16px; font-weight: 500; color: black;">{{ item.currencyName }}</span>
                  </v-flex>

                  <v-flex md12 lg12 align-self-center>
                      <span style="font-family: Lato-Regular; font-size: 16px; font-weight: 500; color: black;">{{ item.currencyCode }}</span>
                  </v-flex>

                </v-layout>
              </v-flex>

              <v-flex lg2 align-self-center>
                <v-layout wrap justify-center>
                  <v-flex lg12 class="text-center">
                    <span style="font-family: Lato-Regular; font-size: 16px; font-weight: 500; color: black;">{{ item.currencySymbol }}</span>
                  </v-flex>
                </v-layout>
              </v-flex>

              </v-layout>
              <!-- <v-layout wrap justify-center>
                  <v-flex md12 lg6 class="text-center">
                      <v-btn small color="#00A08E" @click="countryEdit(item._id,item.name,item.currencyName,item.currencyCode,item.currencySymbol)"><span style="color: white;" >Edit</span></v-btn>
                  </v-flex>
                  <v-flex md12 lg6 class="text-center">
                      <v-btn small @click="deleteRegion(item._id)"><span style="color: #00A08E;">Delete</span></v-btn>
                  </v-flex>
              </v-layout> -->
          </v-card>
        </v-flex>
      </v-layout>
  
        </v-flex>
      </v-layout>
  
      
  
      <v-dialog
        v-model="addCountryDialog"
        width="25%"
        content-class="dialog-custom"
      >
        <v-card>
          <v-layout wrap justify-center pa-8 pt-6>
            <v-flex lg12>
              <v-layout wrap justify-center>
                <v-flex lg11 pb-3>
                  <span
                    style="
                      font-family: Montserrat-Regular;
                      font-size: 24px;
                      font-weight: 700;
                    "
                    >New Country
                  </span>
                </v-flex>
                <v-flex lg1>
                  <v-btn icon>
                    <v-icon @click="addCountryDialog = false">mdi-close</v-icon>
                  </v-btn>
                </v-flex>
  
                <v-flex lg12>
                  <span
                    style="
                      font-family: Lato-Regular;
                      font-size: 16px;
                      font-weight: 500;
                    "
                  >
                    Country Name
                  </span>
                </v-flex>
                <v-flex lg12 pt-1>
                  <v-text-field
                    outlined
                    dense
                    type="text"
                    placeholder="Name"
                    v-model="countryName"
                    hide-details="auto"
                  ></v-text-field>
                </v-flex>

                <v-flex lg12 pt-1>
                <span style="font-family: Lato-Regular; font-size: 16px; font-weight: 500;">
                  Region
                </span>
              </v-flex>
              <v-flex lg12 pt-1>
                <v-select
                  outlined
                  dense
                  :items="region"
                  item-text="name"
                  item-value="_id"
                  placeholder="Select Region"
                  v-model="Region"
                  hide-details="auto"
                ></v-select>
              </v-flex>

                <v-flex lg12 pt-1>
                  <span
                    style="
                      font-family: Lato-Regular;
                      font-size: 16px;
                      font-weight: 500;
                    "
                  >
                    Currency Name
                  </span>
                </v-flex>
                <v-flex lg12 pt-1>
                  <v-text-field
                    outlined
                    dense
                    type="text"
                    placeholder="Name"
                    v-model="currencyName"
                    hide-details="auto"
                  ></v-text-field>
                </v-flex>

                <v-flex lg12 pt-1>
                <span style="font-family: Lato-Regular; font-size: 16px; font-weight: 500;">
                  Currency Code
                </span>
              </v-flex>
              <v-flex lg12 pt-1>
                <v-select
                  outlined
                  dense
                  :items="currencyCodes"
                  item-text="currency_code"
                  item-value="currency_code"
                  placeholder="Select Currency Code"
                  v-model="selectedCurrencyCode"
                  hide-details="auto"
                ></v-select>
              </v-flex>

              <v-flex lg12 pt-1>
                  <span
                    style="
                      font-family: Lato-Regular;
                      font-size: 16px;
                      font-weight: 500;
                    "
                  >
                    Currency Symbol
                  </span>
                </v-flex>
                <v-flex lg12 pt-1>
                  <v-text-field
                    outlined
                    dense
                    type="text"
                    placeholder="Symbol"
                    v-model="currencySymbol"
                    hide-details="auto"
                  ></v-text-field>
                </v-flex>

                <v-flex lg12 pt-5 class="text-center">
                  <v-btn color="rgba(0, 160, 142, 1)" @click="addCountry">
                    <span
                      style="
                        color: white;
                        font-family: Montserrat-Regular;
                        font-size: 14px;
                        font-weight: 600;
                        text-transform: none;
                      "
                    >
                      Create Country
                    </span>
                  </v-btn>
                </v-flex>


              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-dialog>
  
      <v-dialog
        v-model="editCountryDialog"
        width="25%"
        content-class="dialog-custom"
      >
        <v-card>
          <v-layout wrap justify-center pa-8 pt-6>
            <v-flex lg12>
              <v-layout wrap justify-center>
                <v-flex lg11 pb-3>
                  <span
                    style="
                      font-family: Montserrat-Regular;
                      font-size: 24px;
                      font-weight: 700;
                    "
                    >Edit Country
                  </span>
                </v-flex>
                <v-flex lg1>
                  <v-btn icon>
                    <v-icon @click="editCountryDialog = false">mdi-close</v-icon>
                  </v-btn>
                </v-flex>
  
                <v-flex lg12>
                  <span
                    style="
                      font-family: Lato-Regular;
                      font-size: 16px;
                      font-weight: 500;
                    "
                  >
                    Country Name
                  </span>
                </v-flex>
                <v-flex lg12>
                  <v-text-field
                    outlined
                    dense
                    type="text"
                    placeholder="Country"
                    v-model="editCountryName"
                    hide-details="auto"
                  ></v-text-field>
                </v-flex>

                <v-flex lg12>
                  <span
                    style="
                      font-family: Lato-Regular;
                      font-size: 16px;
                      font-weight: 500;
                    "
                  >
                    Currency Name
                  </span>
                </v-flex>
                <v-flex lg12>
                  <v-text-field
                    outlined
                    dense
                    type="text"
                    placeholder="Currency"
                    v-model="editCurrencyName"
                    hide-details="auto"
                  ></v-text-field>
                </v-flex>

                <v-flex lg12>
                  <span
                    style="
                      font-family: Lato-Regular;
                      font-size: 16px;
                      font-weight: 500;
                    "
                  >
                    Currency Code
                  </span>
                </v-flex>

              <v-flex lg12>
                <v-select
                  outlined
                  dense
                  :items="currencyCodes"
                  item-text="currency_code"
                  item-value="currency_code"
                  placeholder="Select Currency Code"
                  v-model="editCurrencyCode"
                  hide-details="auto"
                >
                <template v-slot:item="{ item, on, attrs }">
      <v-list-item v-bind="attrs" v-on="on">
        <v-list-item-title>{{ item.currency_code }}</v-list-item-title>
      </v-list-item>
    </template>  
              </v-select>
              </v-flex>

                <v-flex lg12>
                  <span
                    style="
                      font-family: Lato-Regular;
                      font-size: 16px;
                      font-weight: 500;
                    "
                  >
                    Currency Symbol
                  </span>
                </v-flex>
                <v-flex lg12>
                  <v-text-field
                    outlined
                    dense
                    type="text"
                    placeholder="Symbol "
                    v-model="editCurrencySymbol"
                    hide-details="auto"
                  ></v-text-field>
                </v-flex>

                <v-flex lg12 pt-5 class="text-center">
                  <v-btn color="rgba(0, 160, 142, 1)" @click="editCountry()">
                    <span
                      style="
                        color: white;
                        font-family: Montserrat-Regular;
                        font-size: 14px;
                        font-weight: 600;
                        text-transform: none;
                      "
                    >
                      Submit
                    </span>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-dialog>
      
    </div>
  </template>
  
  <script>
  
  import axios from "axios";
  export default {
    data() {
      return {
        appLoading: false,
        showsnackbar: false,
        ServerError: false,
        msg: null,
        pages: 0,
        country: [],
        addCountryDialog: false,
        regionName: "",
        countryName: "",
        editCountryDialog: false,
        editCountryName: "",
        regionId: "",
        countryId: "",
        currencyName: "",
        selectedCurrencyCode: "",
        currencyCodes:[],
        region:[],
        Region:"",
        currencySymbol:"",
        editCurrencyCode:"",
        editCurrencySymbol:"",
        editCurrencyName:"",
      };
    },
    mounted() {
      this.getCountry();
      this.getCurrencyCodes();
      this.getRegion();
    },
    methods: {
      getCountry() {
        this.appLoading = true;
        axios({
          url: "/country/list",
          method: "GET",
          headers: {
            token: localStorage.getItem("token"),
          },
        })
          .then((response) => {
            this.appLoading = false;
            this.country = response.data.data;
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      },
      
      getRegion() {
      this.appLoading = true;
      axios({
        url: "/region/list",
        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.region = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  
      addCountry() {
        axios({
          method: "POST",
          url: "/country/add",
          headers: {
            token: localStorage.getItem("token"),
          },
          data: {
            name: this.countryName,
            regionId: this.Region,
            currencyName: this.currencyName,
            currencyCode: this.selectedCurrencyCode,
            currencySymbol: this.currencySymbol,
          },
        })
          .then((response) => {
            if (response.data.status) {
              this.getCountry();
              this.addCountryDialog = false; 
            } else {
              this.msg = response.data.msg;
              this.showsnackbar = true;
            }
          })
          .catch((err) => {
            this.ServerError = true;
            console.log(err);
          });
      },
  
      countryEdit(id,name,cname,ccode,csymbol){
        this.editCountryDialog = true;
        this.editCountryName = name;
        this.editCurrencyName = cname;
        this.editCurrencyCode = ccode;
        this.editCurrencySymbol = csymbol;
        this.countryId = id;
      },
  
      editCountry(){
        axios({
          method: "POST",
          url: "/country/edit",
          headers: {
            token: localStorage.getItem("token"),
          },
          data: {
            id: this.countryId,
            name: this.editCountryName,
            currencyName: this.editCurrencyName,
            currencyCode: this.editCurrencyCode,
            currencySymbol: this.editCurrencySymbol,
          },
        })
          .then((response) => {
            if (response.data.status) {
              this.editCountryDialog = false;
              this.getCountry();
              this.addCountryDialog = false; 
            } else {
              this.msg = response.data.msg;
              this.showsnackbar = true;
            }
          })
          .catch((err) => {
            this.ServerError = true;
            console.log(err);
          });
      },

      deleteRegion(id){
        axios({
          method: "POST",
          url: '/country/delete/' + id,
          headers: {
            token: localStorage.getItem("token"),
          },
        })
          .then((response) => {
            if (response.data.status) {
              this.getCountry();
              this.addCountryDialog = false; 
            } else {
              this.msg = response.data.msg;
              this.showsnackbar = true;
            }
          })
          .catch((err) => {
            this.ServerError = true;
            console.log(err);
          });
      },

      getCurrencyCodes() {
      this.appLoading = true;
      axios({
        url: "/currency/list",
        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.currencyCodes = response.data.data.map(currency => ({
            currency_code: currency.currency_code,
            currency_name: currency.currency_name,
            country: currency.country
          }));
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    },
  };
  </script>
  
  
  <style scoped>
  .tile {
    border-radius: 10px;
    height: 115px;
  }
  .v-list-item--active {
  background-color: transparent !important; /* Removes the background */
}
  </style>
  